<template>
  <div class="container">
    <h4 class="text-center my-4">Прикрепление пререквизитов</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3 row">


      <DataTable class="mt-4" :value="departmentMagistracyPrerequisite_form.educationPrograms" :paginator="true" :rows="10"
                 showGridlines
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 filterDisplay="menu"
                 :globalFilterFields="['education_speciality_name', 'education_speciality_code']"
                 v-model:filters="filters"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" stripedRows
                 responsiveLayout="scroll">

        <template #header>
          <div class="p-d-flex p-jc-between p-ai-center">
              <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Поиск ОП"/>
              </span>
          </div>
        </template>

        <Column field="education_speciality_code" header="Код ОП"></Column>

        <Column header="Название ОП">
          <template #body="{data}">
            <router-link
                :to="'/formation-education-program?education_program_id=' + data.id + '&decree_goso_template_id=' + 6">
              {{ data.education_speciality_name }}
            </router-link>
          </template>
        </Column>
        <Column field="language" header="Язык обучения">
          <template #body="{data}">
            {{ data.language ? data.language : '' }}
          </template>
          <template #filter="{filterModel}">
            <Dropdown v-model="filterModel.value" :options="studyLanguages" placeholder="Любой"
                      class="p-column-filter" :showClear="true">
              <template #value="slotProps">
                <span v-if="slotProps.value">{{slotProps.value}}</span>
                <span v-else>{{slotProps.placeholder}}</span>
              </template>
              <template #option="slotProps">
                <span>{{slotProps.option}}</span>
              </template>
            </Dropdown>
          </template>
        </Column>

        <Column field="admission_year" header="Год набора">

          <template #filter="{filterModel}">
            <Dropdown v-model="filterModel.value" :options="admissionYears" placeholder="Любой"
                      class="p-column-filter" :showClear="true">
              <template #value="slotProps">
                <span v-if="slotProps.value">{{slotProps.value}}</span>
                <span v-else>{{slotProps.placeholder}}</span>
              </template>
              <template #option="slotProps">
                <span>{{slotProps.option}}</span>
              </template>
            </Dropdown>
          </template>
        </Column>
        <Column field="prerequisite" header="Пререквизиты">
          <template #body="{data}">
            <!--            {{data.discipline_prerequisite}}-->
            <!--            <br>-->
            <Button class="me-3" icon="pi pi-plus" @click="openPrerequisiteDialog(data.education_program_id)" />
            <Button class="p-button-warning me-3" icon="pi pi-info" @click="openInfoPrerequisiteDialog(data.education_program_id)" />
          </template>
        </Column>





      </DataTable>



      <Dialog header="Добавление пререквизита" :visible="prerequisiteDisplay" style="width: 100%; max-width: 900px" :closable="false">


        <div class="col-md-12"  style="height: 600px">
          <Button icon="pi pi-plus" label="Добавить дисциплину" @click="addRowPrerequisiteDiscipline" />
          <div class="form-group row mt-4" v-for="(discipline, index) in prerequisiteDisciplines" :key="index">
            <div class="remove-item__button d-flex flex-row-reverse mb-2">
              <i class="fa fa-times" @click="deleteRowPrerequisiteDiscipline(index)"></i>
            </div>
            <label class="col-md-3 col-form-label">Поиск дисциплины</label>
            <div class="col-md-6">
              <DropdownListEducationDiscipline
                  :options="options"
                  @search="onSearch"
                  @change="changeSelectedEducationDisciplinePrerequisite(index, $event)"
              />
            </div>
            <div class="col-md-3">
              <input type="number" class="form-control"
                     placeholder="Кредит"
                     @input="changeCredit(index, $event)">
            </div>
          </div>
        </div>
        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" @click="closePrerequisiteDialog" class="p-button-text"/>
          <Button label="Сохранить" icon="pi pi-check" @click="submitPrerequisite" :loading="btnSaveDisabled" />
        </template>
      </Dialog>


      <Dialog header="Пререквизиты" :visible="infoPrerequisiteDisplay" style="width: 100%; max-width: 900px" :closable="false">


        <div class="col-md-12">


          <div class="row mt-4">
            <div v-if="departmentMagistracyPrerequisite_form.educationPrograms.find(i=>i.education_program_id == info_education_program_id).disciplines.length > 0">
              <ul>
                <li class="mb-2" v-for="(item, index) in departmentMagistracyPrerequisite_form.educationPrograms.find(i=>i.education_program_id == info_education_program_id).disciplines"
                    :key="index">
                  {{item.discipline_name}} - {{item.credit}}
                  <Button class="p-button-danger ms-3" icon="pi pi-trash" @click="deleteRequisiteDiscipline(item.prerequisites_id)" />
                </li>
              </ul>
            </div>
            <div v-else>
              <p>Дисциплины не добавлены</p>
            </div>
          </div>

        </div>
        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" @click="closeInfoPrerequisiteDialog" class="p-button-text"/>
        </template>
      </Dialog>


    </div>



  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {FilterMatchMode, FilterOperator} from "primevue/api";
import DropdownListEducationDiscipline from "@/components/common/DropdownListEducationDiscipline.vue";
export default {
  name: 'BindPrerequisite',
  components: {
    DropdownListEducationDiscipline
  },
  data() {
    return {
      loading: true,
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'education_speciality_name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'education_speciality_code': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'admission_year': {value: null, matchMode: FilterMatchMode.EQUALS},
        'language': {value: null, matchMode: FilterMatchMode.EQUALS},
      },
      studyLanguages: ['русский язык', 'қазақ тілі', 'English'],
      prerequisiteDisplay: false,
      infoPrerequisiteDisplay: false,
      prerequisiteDisciplines: [],
      options: [],
      btnSaveDisabled: false,
      selected_education_program_id: null,
      info_education_program_id: null
    }
  },
  computed: {
    ...mapState('departmentMagistracyPrerequisite', ['departmentMagistracyPrerequisite_form']),
    //...mapState('educationprogram', ['educationprogram_form', 'pageCount']),
  },
  methods: {
    ...mapActions('departmentMagistracyPrerequisite', ['GET_EDUCATION_PROGRAMS', 'POST_MAGISTRACY_PREREQUISITE', 'DELETE_MAGISTRACY_PREREQUISITE_DISCIPLINE']),
    //...mapActions('educationprogram', ['GET_EDUCATION_PROGRAM_DATA']),
    ...mapActions('syllabus', ['SEARCH_DISCIPLINE']),
    openPrerequisiteDialog(education_program_id) {
      this.prerequisiteDisplay = true
      this.selected_education_program_id = education_program_id
      console.log(this.selected_education_program_id, 'selected_education_program_id')
    },
    closePrerequisiteDialog() {
      this.prerequisiteDisplay = false
      this.prerequisiteDisciplines = []
      this.selected_education_program_id = null
    },
    openInfoPrerequisiteDialog(education_program_id) {
      this.infoPrerequisiteDisplay = true
      this.info_education_program_id = education_program_id
    },
    closeInfoPrerequisiteDialog(syllabus_id) {
      this.infoPrerequisiteDisplay = false
    },
    async deleteRequisiteDiscipline(id) {
      await this.DELETE_MAGISTRACY_PREREQUISITE_DISCIPLINE(id)
      await this.GET_EDUCATION_PROGRAMS()
    },
    addRowPrerequisiteDiscipline() {
      this.prerequisiteDisciplines.push({
        education_discipline_id: null,
        credit: null,
      });
      console.log(this.prerequisiteDisciplines, 'prerequisiteDisciplines')
    },
    deleteRowPrerequisiteDiscipline(index) {
      this.prerequisiteDisciplines.splice(index, 1);
      console.log(this.prerequisiteDisciplines, 'prerequisiteDisciplines')
    },
    onSearch(search) {
      if (search.length > 3) {
        this.SEARCH_DISCIPLINE(search).then(json => (this.options = json));
      }
    },
    changeSelectedEducationDisciplinePrerequisite(index, e) {
      let selectedEducationDisciplineId = e.id
      console.log(selectedEducationDisciplineId, 'selectedEducationDisciplineId')
      console.log(index, 'index')
      this.prerequisiteDisciplines[index].education_discipline_id = selectedEducationDisciplineId
      console.log(this.prerequisiteDisciplines, 'prerequisiteDisciplines')
    },
    changeCredit(index, e, val = 'value') {
      const value = e.target[val]

      this.prerequisiteDisciplines[index].credit = value
      console.log(this.prerequisiteDisciplines, 'prerequisiteDisciplines')
    },

    async submitPrerequisite() {
      this.btnSaveDisabled = true
      // this.prerequisiteDisplay = false

      let form = this.prerequisiteDisciplines.map(i => ({
        education_program_id: this.selected_education_program_id,
        education_discipline_id: i.education_discipline_id,
        credit: i.credit,
      }))

      console.log(form, 'submit prerequisite form')


      let postData = await this.POST_MAGISTRACY_PREREQUISITE(form)
      if (postData) {

        await this.GET_EDUCATION_PROGRAMS()
        this.$message({text: 'Пререквизиты добавлены'})

      }
      this.btnSaveDisabled = false
      this.prerequisiteDisciplines = []
      // this.selected_education_program_id = null

    },
  },
  async mounted() {
    await this.GET_EDUCATION_PROGRAMS();
    //await this.GET_EDUCATION_PROGRAM_DATA();
    this.loading = false
  },
}
</script>